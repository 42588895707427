@if(loading){
<div class="spinner-container">
  <ion-spinner name="dots"> </ion-spinner>
</div>
}
<!--  -->
@if(!loading && userCompanies){
<!-- Empleado -->
<ion-grid>
  <ion-row>
    <div
      class="flex justify-content-center align-items-center"
      style="gap: 1vw; width: 100%"
    >
      @if(companiesByUser['companies'] != ''){
      <div class="card flex justify-content-center">
        <p-carousel
          [value]="companiesByUser['companies']"
          [numVisible]="1"
          [numScroll]="1"
          orientation="vertical"
          verticalViewPortHeight="400px"
        >
          <ng-template pTemplate="header">
            <h5>Empresas donde eres asociado</h5>
          </ng-template>
          <ng-template let-product pTemplate="item">
            <div class="border-1 surface-border border-round m-2 p-3 h-50">
              <div class="mb-3">
                <div class="flex align-items-center justify-content-center">
                  @defer(){
                  <img
                    src="assets/images/logo-default.png"
                    style="width: 25vw"
                    class="border-round"
                  />
                  }
                </div>
              </div>
              <div class="mb-3">
                <h5>{{ product.company_name }}</h5>
              </div>
              <div class="flex justify-content-center align-items-center">
                <span>
                  <p-button
                    label="Ver detalles"
                    (click)="goTo(product.id)"
                    class="primary"
                  />
                </span>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
      } @if(companiesByUser['employees'] != ''){
      <div class="card flex justify-content-center">
        <p-carousel
          [value]="companiesByUser['employees']"
          [numVisible]="1"
          [numScroll]="1"
          orientation="vertical"
          verticalViewPortHeight="550px"
        >
          <ng-template pTemplate="header">
            <h5>Empresas donde eres empleado</h5>
          </ng-template>
          <ng-template let-product pTemplate="item" >
            <div class="border-1 surface-border border-round m-2 p-3">
              <div class="mb-3">
                <div class="flex align-items-center justify-content-center">
                  @defer(){
                  <img
                    src="assets/logos/logo.png"
                    style="width: 25vw"
                    class="border-round"
                  />
                  }
                </div>
              </div>
              <div class="mb-3 font-medium">
                <h5>{{ product.company_name }}</h5>
              </div>
            </div>
            <div class="flex justify-content-center align-items-center">
              <span>
                <p-button label="Ver detalles" (click)="goTo(product.id)" />
              </span>
            </div>
          </ng-template>
        </p-carousel>
      </div>
      }
    </div>
  </ion-row>
</ion-grid>

<div
  class="flex justify-content-center align-items-center"
  style="gap: 1vw; width: 100%"
>
  @if(companiesByUser['customers'] != ''){
  <div class="card flex justify-content-center">
    <p-carousel
      [value]="companiesByUser['customers']"
      [numVisible]="1"
      [numScroll]="1"
      orientation="vertical"
      verticalViewPortHeight="400px"
    >
      <ng-template pTemplate="header">
        <h5>Empresas donde eres cliente</h5>
      </ng-template>
      <ng-template let-product pTemplate="item">
        <div class="border-1 surface-border border-round m-2 p-3">
          <div class="mb-3">
            <div class="flex align-items-center justify-content-center">
              @defer(){
              <img
                src="assets/logos/logo.png"
                style="width: 25vw"
                class="border-round"
              />
              }
            </div>
          </div>
          <div class="mb-3">
            <h5>{{ product.company_name }}</h5>
          </div>
          <div class="flex justify-content-center align-items-center">
            <span>
              <p-button label="Ver detalles" (click)="goTo(product.id)" />
            </span>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
  } @if(companiesByUser['suppliers'] != ''){
  <div class="card flex justify-content-center">
    <p-carousel
      [value]="companiesByUser['suppliers']"
      [numVisible]="1"
      [numScroll]="1"
      orientation="vertical"
      verticalViewPortHeight="400px"
    >
      <ng-template pTemplate="header">
        <h5>Empresas donde eres proveedor</h5>
      </ng-template>
      <ng-template let-product pTemplate="item">
        <div class="border-1 surface-border border-round m-2 p-3">
          <div class="mb-3">
            <div class="flex align-items-center justify-content-center">
              @defer(){
              <img
                src="assets/logos/logo.png"
                style="width: 25vw"
                class="border-round"
              />
              }
            </div>
          </div>
          <div class="mb-3">
            <h5>{{ product.company_name }}</h5>
          </div>
          <div class="flex justify-content-center align-items-center">
            <span>
              <p-button label="Ver detalles" (click)="goTo(product.id)" />
            </span>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
  }
</div>
}
